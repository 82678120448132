/* eslint-disable formatjs/enforce-placeholders */
import { Typography } from '@mui/material';
import { isObject, pick } from 'lodash-es';
import { evaluate } from 'mathjs';

export const calculateValidationRules = (validationRules, kpiValuesSum = 0) => {
   if (Array.isArray(validationRules) && (validationRules ?? []).length > 0) {
      const sumOfValidations = [];
      let _kpiValuesSum = Number.isFinite(kpiValuesSum) ? kpiValuesSum : 0;
      const rules = (validationRules ?? []).map((rule) => {
         let min = rule?.referenceValueSum;
         let max = rule?.referenceValueSum;
         let toleranceValue = rule?.toleranceValue ?? 0;
         if ('PERCENTAGE' === rule?.tolerance) {
            toleranceValue = (toleranceValue / 100) * rule?.referenceValueSum;
         }
         if ('NONE' !== rule?.tolerance) {
            min = rule?.referenceValueSum - toleranceValue;
            max = rule?.referenceValueSum + toleranceValue;
         }

         let calcResult;
         if (['>=', '>'].includes(rule?.comparator)) {
            calcResult = evaluate(`${_kpiValuesSum} ${rule?.comparator} ${min}`);
         } else if (['<=', '<'].includes(rule?.comparator)) {
            calcResult = evaluate(`${_kpiValuesSum} ${rule?.comparator} ${max}`);
         } else if (['!='].includes(rule?.comparator)) {
            calcResult = evaluate(`${_kpiValuesSum} < ${min}`) || evaluate(`${_kpiValuesSum} > ${max}`);
         } else if (['=='].includes(rule?.comparator)) {
            calcResult = evaluate(`${min} <= ${_kpiValuesSum} <= ${max}`);
         }

         sumOfValidations.push(calcResult);
         return {
            ...rule,
            min,
            max,
         };
      });
      return sumOfValidations.map((ruleResult, index) => (ruleResult ? undefined : rules[index])).filter((ruleResult) => ruleResult);
   }
   return [];
};

export const getValidationRuleErrors = (intl, validationRuleMessages) => (
   <>
      <Typography variant="inherit">
         {intl.formatMessage({ id: 'validationRule.errorMessages.headline', defaultMessage: 'Validation rules apply:' })}
      </Typography>
      {(validationRuleMessages ?? []).map((rule) => (
         <Typography variant="inherit" key={rule?.id}>
            {intl.formatMessage(
               {
                  id: `validationRule.errorMessages.${rule?.comparator}`,
                  defaultMessage: 'Validation Rule does not fit for the field value.',
               },
               {
                  maxValue: rule?.max,
                  minValue: rule?.min,
                  toleranceAddition: intl.formatMessage(
                     {
                        id: `validationRule.errorMessages.${rule?.tolerance}`,
                        defaultMessage: rule?.tolerance === 'NONE' ? ' ' : ` (including tolerance of {toleranceValue})`,
                     },
                     {
                        toleranceValue: rule?.toleranceValue,
                     }
                  ),
               }
            )}
         </Typography>
      ))}
   </>
);

export const getFormattedFormula = (rawFormula) => {
   const formula = typeof rawFormula === 'string' ? JSON.parse(rawFormula) : rawFormula;

   return (formula ?? []).map((formulaItem) => {
      if (isObject(formulaItem)) {
         let row = {};
         if (isObject(formulaItem?.row)) {
            row = { row: formulaItem?.row?.slug };
         } else if (typeof formulaItem?.row === 'string') {
            row = { row: formulaItem?.row };
         }
         return {
            ...pick(formulaItem, ['slug', 'kind', 'type', 'relativePeriods', 'scope', 'category', 'method', 'sourceType', 'valueSource', 'value']),
            ...row,
         };
      } else {
         return formulaItem;
      }
   });
};
